import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  RecruitWrap,
  RecruitInner,
  RecruitTitle,
  RecruitHeader,
  RequirementWrap,
  RequirementTitle,
  RequirementTable,
  RequirementFlow,
  RequirementFlowNote,
  RequirementButton,
} from "../../components/recruit/recruit-parts"

import RecruitSwitcher from "../../components/recruit/recruit-switcher"

const Recruit: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Recruit　求人募集"
        description="東京都八丈島への移住・定住をご希望の方、Ｕターンの方も大歓迎です。"
      />
      <RecruitSwitcher>
        <RecruitWrap>
          <RecruitInner>
            <RecruitTitle>Recruit</RecruitTitle>
            <RecruitHeader>【2022年新卒】募集要項</RecruitHeader>

            <RequirementWrap>
              <RequirementTitle>募集職種</RequirementTitle>
              <p>
                ・SDGsビジネスプロデューサーアシスタント
                <br />
                ・空間デザイナーアシスタント
                <br />
                ・UI/UXデザイナーアシスタント
              </p>
            </RequirementWrap>

            <RequirementWrap>
              <RequirementTitle>給与</RequirementTitle>
              <p>
                <span className="strong">月給210,000円（予定）</span>
                <br />
                ※上記には固定残業手当（35時間/44,540円）、深夜勤務手当（10時間/2,550円）を含みます。
                <br />
                ※超過分は別途支給します。
              </p>
            </RequirementWrap>

            <RequirementWrap>
              <RequirementTitle>条件など</RequirementTitle>
              <RequirementTable>
                <tr>
                  <th>勤務地</th>
                  <td>東京都八丈島八丈町三根1988-2</td>
                </tr>
                <tr>
                  <th>勤務時間</th>
                  <td>9:00～18:00（休憩60分）</td>
                </tr>
                <tr>
                  <th>休日・休暇</th>
                  <td>
                    完全週休二日制、祝日、特別休暇、年末年始休暇、年次有給休暇、慶弔休暇
                  </td>
                </tr>
                <tr>
                  <th>各種手当</th>
                  <td>健康保険、厚生年金保険、雇用保険、労災保険</td>
                </tr>
                <tr>
                  <th>各種保険</th>
                  <td>時間外手当、深夜手当、休日出勤手当、通勤手当</td>
                </tr>
                <tr>
                  <th>各種制度</th>
                  <td>従業員持株会制度、保養所など</td>
                </tr>
                <tr>
                  <th>受動喫煙防止措置</th>
                  <td>屋内原則禁煙</td>
                </tr>
                <tr>
                  <th>定年</th>
                  <td>60歳　再雇用制度有</td>
                </tr>
                <tr>
                  <th>退職金</th>
                  <td>無し</td>
                </tr>
                <tr>
                  <th>賞与</th>
                  <td>有り</td>
                </tr>
                <tr>
                  <th>昇給</th>
                  <td>年1回</td>
                </tr>
              </RequirementTable>
            </RequirementWrap>

            <RequirementWrap>
              <RequirementTitle>エントリーから採用までの流れ</RequirementTitle>
              <RequirementFlow>
                <li>エントリー</li>
                <li>書類選考</li>
                <li>面接（Zoom）</li>
                <li>採用</li>
                <li>入居※</li>
              </RequirementFlow>
              <RequirementFlowNote>
                ※オフィスの隣にシェアハウス型の住居棟を備えています。
                八丈島での住居が見つかるまで最大6カ月滞在できます。
              </RequirementFlowNote>
            </RequirementWrap>

            <RequirementButton
              to="/recruit/form"
              state={{ jobType: "newgrad" }}
            >
              応募する
            </RequirementButton>
          </RecruitInner>
        </RecruitWrap>
      </RecruitSwitcher>
    </Layout>
  )
}

export default Recruit
